.about {
  flex-direction: column;
  margin-top: 10em;

}
.about__name {
  color: var(--clr-primary);
}
.about > :nth-child(2) {
  margin-top: 1.2em;
  font-size: 2rem;
  line-height: 1.2;
  color: var(--clr-fg-alt);
}

.about__desc {
  font-size: 1rem;
  max-width: 70%;
  text-align: center;
}

.about__desc,
.about__contact {
  margin-top: 2.4em;
}

.about .link--icon {
  margin-right: 0.8em;
}

.about .btn--outline {
  margin-right: 1em;
}
.btnResume{
  color: var(--clr-primary);
  border: 2px solid var(--clr-primary);
  border-radius: 5px;
  height: 50px;
  cursor: pointer;
  padding: 0px 30px;
  margin-top: 10px;
}
.btnResume:hover{
  background-color: var(--clr-primary);
  color: var(--clr-bg);
}

@media (max-width: 600px) {
  .mobileHead{
    align-content: center;
  }
  .app .about {
    align-items: flex-start;
    margin-top: 2em;
    justify-content: center;
    align-items: center;
  }
  .about > :nth-child(2) {
    font-size: 1.5rem;
  }
}
